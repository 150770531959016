import first from 'lodash/first'
import last from 'lodash/last'
import omit from 'lodash/omit'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import {
  FORM_CHANGES_FIELD_PREFIX,
  FIELD_NAMES,
  GROUP_FIELD_NAME_PREFIX,
} from 'const/forms'
import { NAME_FOR_CASHBACK_CREATION } from 'const/retailers'
import { updateEntity, createEntity, deleteEntity } from 'domains/retailers/api'
import {
  RELATIONSHIPS_NAMES,
  ENTITY_NAMES,
  ENTITY_ATTRS,
  // CASHBACK_ENTITIES_WITH_DATES,
} from 'const/api'
import { hasSetTheSameImages } from '../images'
import { EMPTY_IMAGE_SET } from 'const/images'
import { DEFAULT_MEDIA_TYPE } from 'const/adPackage'
import { getPercentInFloat } from 'helpers/float'

export const getAttrsPatchData = ({
  fieldName,
  type,
  retailerId,
  value,
  shouldSave,
  path,
  [FIELD_NAMES.ruleId]: ruleId,
}) =>
  shouldSave && {
    retailerId,
    id: retailerId,
    [FIELD_NAMES.ruleId]: ruleId,
    path,
    type,
    attributes: {
      [fieldName]: value,
    },
  }

// export const getCashbackCreateData = ({ type, retailerId, value, shouldSave, path }) =>
//   shouldSave && {
//     retailerId,
//     type,
//     path,
//     attributes: {
//       name: NAME_FOR_CASHBACK_CREATION,
//       value: value,
//     },
//     relationships: {
//       retailer: {
//         data: {
//           id: retailerId,
//           type: 'retailers',
//         }
//       },
//     }
//   }

export const getCreateData = ({ type, retailerId, value, path }) => ({
  type,
  retailerId,
  path,
  attributes: {
    ...value,
  },
  relationships: {
    retailer: {
      data: {
        id: retailerId,
        type: 'retailers',
      },
    },
  },
})

export const getEditData = ({ type, id, value, path }, retailerId) => ({
  type,
  retailerId,
  id,
  path,
  attributes: {
    ...value,
  },
})

// export const getCashbackDeleteData = ({ id, type, shouldSave, path, retailerId }) =>
//   shouldSave && {
//     retailerId,
//     id,
//     type,
//     path,
//   }

export const getDeleteData = ({ id, type, path }, retailerId) => ({
  id,
  retailerId,
  type,
  path,
})

// export const getCreateOrDeleteData = ({ type, retailerId, id, value, shouldSave, path }) => {
//   if (shouldSave) {
//     if (id && !value) return getCashbackDeleteData({ id, type, shouldSave, path, retailerId })
//     return CASHBACK_ENTITIES_WITH_DATES.includes(type)
//       ? getCashbackCreateDataWithDates({ value, type, retailerId, shouldSave, path })
//       : getCashbackCreateData({ type, value, retailerId, shouldSave, path})
//   }
// }

// const getNewValueWithDate = (changes, oldValues) => {
//   if (changes?.cb < 1) return null
//   const newDates = [
//     changes?.dates?.[0] ? changes.dates?.[0] : oldValues?.dates[0],
//     changes?.dates?.[1],
//   ]
//   const dates = changes?.dates ? newDates : oldValues.dates
//   return {
//     cb: changes?.cb || oldValues?.cb,
//     dates,
//   }
// }

export const getCashbackCreateDataWithDates = ({
  type,
  retailerId,
  value,
  shouldSave,
  path,
}) =>
  shouldSave &&
  value.cb && {
    type,
    retailerId,
    path,
    attributes: {
      name: NAME_FOR_CASHBACK_CREATION,
      value: value.cb,
      started_at: first(value.dates),
      finished_at: last(value.dates),
    },
    relationships: {
      retailer: {
        data: {
          id: retailerId,
          type: 'retailers',
        },
      },
    },
  }

const createShowOnUiUpdateConfig = (
  retailerId,
  retailerType,
  visible,
  path
) => ({
  path,
  retailerId,
  method: 'patch',
  url: `/${retailerType}/${retailerId}`,
  data: {
    data: {
      type: retailerType,
      id: retailerId,
      attributes: {
        [ENTITY_ATTRS[FIELD_NAMES.showOnUi]]: visible,
      },
    },
  },
  errorMessage: "Show on UI status hasn't been changed",
})

const createMediaTypeUpdateConfig = (
  retailerId,
  retailerType,
  mediaType,
  path
) => {
  const value = get(mediaType, 'value')
  return {
    path,
    retailerId,
    method: 'patch',
    url: `/${retailerType}/${retailerId}`,
    data: {
      data: {
        type: retailerType,
        id: retailerId,
        attributes: {
          [ENTITY_ATTRS[FIELD_NAMES.mediaType]]:
            value === DEFAULT_MEDIA_TYPE ? null : value,
        },
      },
    },
    errorMessage: "Media type hasn't been changed",
  }
}

const createMainCategoriesConfig = (retailerId, value, path) => ({
  path,
  retailerId,
  method: 'post',
  url: `/${ENTITY_NAMES.mainCategory}`,
  data: {
    data: {
      type: ENTITY_NAMES.mainCategory,
      attributes: {
        main: true,
      },
      relationships: {
        main_retailer: {
          data: {
            id: retailerId,
            type: ENTITY_NAMES.main,
          },
        },
        category: {
          data: {
            type: 'categories',
            id: value.value,
          },
        },
      },
    },
  },
  errorMessage: "Main category hasn't been updated",
})

const createCategoriesAddConfig = (retailerId, data, path) => ({
  path,
  retailerId,
  method: 'post',
  url: `/${RELATIONSHIPS_NAMES.retailers}/${retailerId}/relationships/${RELATIONSHIPS_NAMES.categories}`,
  data: { data },
  errorMessage: "Categories haven't been added",
})

const createCategoriesUpdateConfig = (retailerId, data, path) => ({
  path,
  retailerId,
  method: 'patch',
  url: `/${ENTITY_NAMES.retailerCategory}/${data.id}`,
  data: { data },
  errorMessage: "Main category hasn't been updated",
})

const createCategoriesRemoveConfig = (retailerId, data, path) => ({
  path,
  retailerId,
  method: 'delete',
  url: `/${RELATIONSHIPS_NAMES.retailers}/${retailerId}/relationships/${RELATIONSHIPS_NAMES.categories}`,
  data: { data },
  errorMessage: "Categories haven't been removed",
})

const getRetailerGroupsActivateConfig = (
  retailerId,
  groupId,
  change,
  path
) => ({
  path,
  retailerId,
  method: 'post',
  url: `/${RELATIONSHIPS_NAMES.retailerGroups}`,
  data: {
    data: {
      type: RELATIONSHIPS_NAMES.retailerGroups,
      attributes: {
        [ENTITY_ATTRS[FIELD_NAMES.position]]: change[FIELD_NAMES.position],
      },
      relationships: {
        retailer: {
          data: {
            id: retailerId,
            type: RELATIONSHIPS_NAMES.retailers,
          },
        },
        group: {
          data: {
            id: groupId,
            type: RELATIONSHIPS_NAMES.groups,
          },
        },
      },
    },
  },
  errorMessage: "Group hasn't been activated",
})

const getRetailerGroupsDeactivateConfig = (
  retailersGroupsId,
  path,
  retailerId
) => ({
  path,
  retailerId,
  method: 'delete',
  url: `/${RELATIONSHIPS_NAMES.retailerGroups}/${retailersGroupsId}`,
  errorMessage: "Group hasn't been deactivated",
})

const getRetailerGroupsUpdateConfig = (
  retailersGroupsId,
  change,
  path,
  retailerId
) => ({
  path,
  retailerId,
  method: 'put',
  url: `/${RELATIONSHIPS_NAMES.retailerGroups}/${retailersGroupsId}`,
  data: {
    data: {
      type: RELATIONSHIPS_NAMES.retailerGroups,
      id: retailersGroupsId,
      attributes: {
        [ENTITY_ATTRS[FIELD_NAMES.position]]: change[FIELD_NAMES.position],
      },
    },
  },
  errorMessage: "Group hasn't been updated",
})

const getShowOnUiConfig = (
  retailerId,
  changes,
  valuesToSave,
  retailerType,
  path
) => {
  if (valuesToSave[FIELD_NAMES.showOnUi]) {
    return createShowOnUiUpdateConfig(
      retailerId,
      retailerType,
      changes[FIELD_NAMES.showOnUi],
      path
    )
  }
}

const getMediaTypeConfig = (
  retailerId,
  changes,
  valuesToSave,
  retailerType,
  path
) => {
  if (valuesToSave[FIELD_NAMES.mediaType]) {
    return createMediaTypeUpdateConfig(
      retailerId,
      retailerType,
      changes[FIELD_NAMES.mediaType],
      path
    )
  }
}

const getCategoriesConfigs = (
  retailerId,
  changes,
  valuesToSave,
  path,
  retailersCategoriesRelationships
) => {
  const shouldSave = valuesToSave[FIELD_NAMES.categories]
  const result = []
  if (shouldSave) {
    const change = changes[FIELD_NAMES.categories]
    if (change) {
      const { added, removed, main } = Object.entries(change).reduce(
        (result, [id, selected]) => {
          if (selected) {
            const mainCategoryData = {
              type: RELATIONSHIPS_NAMES.retailerCategories,
              attributes: {
                [ENTITY_ATTRS.main]: selected[FIELD_NAMES.mainCategory],
              },
              id: retailersCategoriesRelationships[id],
            }
            if (retailersCategoriesRelationships[id]) {
              result.main.push(mainCategoryData)
            } else {
              if (selected[FIELD_NAMES.mainCategory]) {
                result.main.push({
                  type: RELATIONSHIPS_NAMES.retailerCategories,
                  attributes: {
                    [ENTITY_ATTRS.main]: true,
                  },
                  value: id,
                })
              } else {
                result.added.push({ type: RELATIONSHIPS_NAMES.categories, id })
              }
            }
          } else {
            result.removed.push({ type: RELATIONSHIPS_NAMES.categories, id })
          }
          return result
        },
        {
          main: [],
          added: [],
          removed: [],
        }
      )
      if (main.length) {
        main.forEach((item) =>
          result.push(
            item.id
              ? createCategoriesUpdateConfig(retailerId, item, path)
              : createMainCategoriesConfig(retailerId, item, path)
          )
        )
      }
      if (added.length) {
        result.push(createCategoriesAddConfig(retailerId, added, path))
      }
      if (removed.length) {
        result.push(createCategoriesRemoveConfig(retailerId, removed, path))
      }
    }
  }
  return result
}

const getRetailerGroupsConfigs = (
  retailerId,
  changes,
  valuesToSave,
  retailersGroupsRelationships
) => {
  if (
    Object.keys(valuesToSave).some((key) =>
      key.startsWith(GROUP_FIELD_NAME_PREFIX)
    )
  ) {
    return Object.entries(changes).reduce((result, [key, change]) => {
      if (key.startsWith(GROUP_FIELD_NAME_PREFIX)) {
        const groupId = key.substring(GROUP_FIELD_NAME_PREFIX.length)
        const path = `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${GROUP_FIELD_NAME_PREFIX}${groupId}]`
        if (valuesToSave[key]) {
          if (change.hasOwnProperty(FIELD_NAMES.active)) {
            result.push(
              change[FIELD_NAMES.active]
                ? getRetailerGroupsActivateConfig(
                    retailerId,
                    groupId,
                    change,
                    path
                  )
                : getRetailerGroupsDeactivateConfig(
                    retailersGroupsRelationships[groupId],
                    path,
                    retailerId
                  )
            )
          } else {
            if (change.hasOwnProperty(FIELD_NAMES.position)) {
              result.push(
                getRetailerGroupsUpdateConfig(
                  retailersGroupsRelationships[groupId],
                  change,
                  path,
                  retailerId
                )
              )
            }
          }
        }
      }
      return result
    }, [])
  }
  return []
}

const getPatchData = ({
  retailerId,
  changes,
  oldValues,
  valuesToSave,
  retailerType,
  entityIds,
  retailersGroupsRelationships,
  retailersCategoriesRelationships,
}) =>
  [
    // getCreateOrDeleteData({
    //   id: entityIds[FIELD_NAMES.cb],
    //   path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.cb}]`,
    //   retailerId,
    //   type: ENTITY_NAMES.cb,
    //   shouldSave: valuesToSave.hasOwnProperty(FIELD_NAMES.cb) && valuesToSave[FIELD_NAMES.cb],
    //   value: changes[FIELD_NAMES.cb]
    // }),
    // getCreateOrDeleteData({
    //   id: entityIds[FIELD_NAMES.promoCb],
    //   retailerId,
    //   type: ENTITY_NAMES.promoCb,
    //   path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.promoCb}]`,
    //   shouldSave: valuesToSave.hasOwnProperty(FIELD_NAMES.promoCb) && valuesToSave[FIELD_NAMES.promoCb],
    //   value: getNewValueWithDate(changes[FIELD_NAMES.promoCb], oldValues[FIELD_NAMES.promoCb]),
    // }),
    // getCreateOrDeleteData({
    //   id: entityIds[FIELD_NAMES.plusCb],
    //   retailerId,
    //   type: ENTITY_NAMES.plusCb,
    //   path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.plusCb}]`,
    //   shouldSave: valuesToSave.hasOwnProperty(FIELD_NAMES.plusCb) && valuesToSave[FIELD_NAMES.plusCb],
    //   value: changes[FIELD_NAMES.plusCb],
    // }),
    // getCreateOrDeleteData({
    //   id: entityIds[FIELD_NAMES.promoPlusCb],
    //   retailerId,
    //   type: ENTITY_NAMES.promoPlusCb,
    //   path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.promoPlusCb}]`,
    //   shouldSave: valuesToSave.hasOwnProperty(FIELD_NAMES.promoPlusCb) && valuesToSave[FIELD_NAMES.promoPlusCb],
    //   value: getNewValueWithDate(changes[FIELD_NAMES.promoPlusCb], oldValues[FIELD_NAMES.promoPlusCb]),
    // }),
    getShowOnUiConfig(
      retailerId,
      changes,
      valuesToSave,
      retailerType,
      `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.showOnUi}]`
    ),
    getMediaTypeConfig(
      retailerId,
      changes,
      valuesToSave,
      retailerType,
      `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.mediaType}]`
    ),
    ...getCategoriesConfigs(
      retailerId,
      changes,
      valuesToSave,
      `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.categories}]`,
      retailersCategoriesRelationships
    ),
    ...getRetailerGroupsConfigs(
      retailerId,
      changes,
      valuesToSave,
      retailersGroupsRelationships
    ),
  ].filter(Boolean)

// const getPatchDataWithCPA = ({
//   retailerId,
//   changes,
//   valuesToSave,
//   retailerType,
// }) => [
//   getAttrsPatchData({
//     retailerId,
//     fieldName: ENTITY_ATTRS[FIELD_NAMES.cpa],
//     path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.cpa}]`,
//     type: retailerType,
//     shouldSave: valuesToSave.hasOwnProperty(FIELD_NAMES.cpa) && valuesToSave[FIELD_NAMES.cpa],
//     value: changes[FIELD_NAMES.cpa] || null,
//   }),
// ].filter(Boolean)

const getPatchDataWithAttrs = ({
  retailerId,
  changes,
  valuesToSave,
  retailerType,
  [FIELD_NAMES.ruleId]: ruleId,
}) =>
  [
    getAttrsPatchData({
      retailerId,
      fieldName: ENTITY_ATTRS[FIELD_NAMES.actionOnClick],
      path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.actionOnClick}]`,
      type: retailerType,
      shouldSave:
        valuesToSave.hasOwnProperty(FIELD_NAMES.actionOnClick) &&
        valuesToSave[FIELD_NAMES.actionOnClick],
      value: changes[FIELD_NAMES.actionOnClick] ? 'show' : 'redirect',
    }),
    getAttrsPatchData({
      retailerId,
      fieldName: ENTITY_ATTRS[FIELD_NAMES.maxKarmaGives],
      path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.maxKarmaGives}]`,
      type: retailerType,
      shouldSave:
        valuesToSave.hasOwnProperty(FIELD_NAMES.maxKarmaGives) &&
        valuesToSave[FIELD_NAMES.maxKarmaGives],
      value: changes[FIELD_NAMES.maxKarmaGives]
        ? getPercentInFloat(changes[FIELD_NAMES.maxKarmaGives])
        : 0,
    }),
    getAttrsPatchData({
      retailerId,
      [FIELD_NAMES.ruleId]: ruleId,
      fieldName: ENTITY_ATTRS[FIELD_NAMES.tagsCount],
      path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.tagsCount}]`,
      type: retailerType,
      shouldSave:
        valuesToSave.hasOwnProperty(FIELD_NAMES.tagsCount) &&
        valuesToSave[FIELD_NAMES.tagsCount],
      value: changes[FIELD_NAMES.tagsCount]
        ? changes[FIELD_NAMES.tagsCount]
        : 0,
    }),
    // getAttrsPatchData({
    //   retailerId,
    //   fieldName: ENTITY_ATTRS.cb,
    //   path: `${FORM_CHANGES_FIELD_PREFIX}_${retailerId}[${FIELD_NAMES.cbActive}]`,
    //   type: retailerType,
    //   shouldSave: valuesToSave.hasOwnProperty(FIELD_NAMES.cbActive) && valuesToSave[FIELD_NAMES.cbActive],
    //   value: changes[FIELD_NAMES.cbActive] ? 'active' : 'inactive',
    // }),
  ].filter(Boolean)

const formatChangesToRequestData = (
  changes,
  formatter,
  valuesToSave,
  oldValues = {},
  entityIds = {},
  retailersGroupsRelationships = {},
  retailersCategoriesRelationships = {}
) =>
  Object.keys(changes).reduce((result, id) => {
    const retailerType = oldValues[id]?.type
    const ruleId = oldValues[id]?.[FIELD_NAMES.ruleId]
    const patchDates = formatter({
      retailerId: id,
      [FIELD_NAMES.ruleId]: ruleId,
      changes: changes[id],
      valuesToSave: valuesToSave[id],
      oldValues: oldValues[id],
      retailerType,
      entityIds: entityIds[id],
      retailersGroupsRelationships: retailersGroupsRelationships[id],
      retailersCategoriesRelationships: retailersCategoriesRelationships[id],
    })
    return result.concat(patchDates)
  }, [])

export const mapRetailerChangesToRequestData = ({
  changes,
  valuesToSave,
  entityIds,
  oldValues,
  retailersGroupsRelationships,
  retailersCategoriesRelationships,
}) => [
  // formatChangesToRequestData(
  //   changes,
  //   getPatchDataWithCPA,
  //   valuesToSave,
  //   oldValues,
  // ),
  formatChangesToRequestData(
    changes,
    getPatchData,
    valuesToSave,
    oldValues,
    entityIds,
    retailersGroupsRelationships,
    retailersCategoriesRelationships
  ),
  formatChangesToRequestData(
    changes,
    getPatchDataWithAttrs,
    valuesToSave,
    oldValues
  ),
]

const getContactsData = ({ newValue = {}, ids, retailerId }) =>
  Object.keys(newValue)?.reduce((result, item) => {
    if (newValue.hasOwnProperty(item)) {
      const id = ids[item]
      const value = newValue[item]
      const data = { id, type: ENTITY_NAMES.contacts, value, retailerId }
      if (id) {
        result.push({
          data: value
            ? getEditData(data, retailerId)
            : getDeleteData(data, retailerId),
          api: value ? updateEntity : deleteEntity,
        })
      } else if (value) {
        result.push({
          data: getCreateData(data),
          api: createEntity,
        })
      }
    }
    return result
  }, [])

const getCommentRequest = ({ newValue, shouldSave, id, retailerId }) => {
  const data = {
    id,
    retailerId,
    type: ENTITY_NAMES.comments,
    value: { text: newValue },
  }
  if (shouldSave) {
    if (newValue) {
      if (id) {
        return {
          data: getEditData(data, retailerId),
          api: updateEntity,
        }
      } else {
        return {
          data: getCreateData(data),
          api: createEntity,
        }
      }
    } else {
      return {
        data: getDeleteData(data, retailerId),
        api: deleteEntity,
      }
    }
  }
}

export const mapRetailerCommentsToRequestData = ({
  retailerId,
  changes,
  ids,
}) =>
  [
    getCommentRequest({
      retailerId,
      newValue: changes?.[FIELD_NAMES.comment],
      shouldSave: changes.hasOwnProperty(FIELD_NAMES.comment),
      id: ids[retailerId]?.[FIELD_NAMES.comment],
    }),
    ...getContactsData({
      retailerId,
      newValue: changes?.[FIELD_NAMES.contacts],
      ids: ids[retailerId]?.[FIELD_NAMES.contacts],
    }),
  ].filter(Boolean)

const getGroupedSetConfig = (
  images,
  additionalData,
  method,
  retailerId,
  shopId,
  setIndex
) => ({
  retailerId,
  shopId,
  method,
  url: `${ENTITY_NAMES.images}/${
    method === 'post' ? 'new_image_set_upload' : 'existing_image_set_upload'
  }`,
  data: {
    data: {
      ...additionalData,
      images,
    },
  },
  index: setIndex,
  errorMessage:
    method === 'post'
      ? `Images for the set ${setIndex + 1} haven't been created`
      : `Images for the set ${setIndex + 1} haven't been updated`,
})

const groupSetImages = (
  set,
  setId,
  additional,
  retailerId,
  shopId,
  setIndex
) => {
  const groups = groupBy(Object.keys(set), (type) => set[type])

  const additionalData = setId
    ? {
        [ENTITY_ATTRS.setId]: setId,
        ...additional,
      }
    : {
        attributes: {
          [ENTITY_ATTRS.retailerId]: retailerId,
          [ENTITY_ATTRS.shopId]: shopId?.toString(),
          ...additional,
        },
      }

  const imageData = Object.keys(
    groupBy(Object.keys(set), (type) => set[type])
  ).reduce(
    (result, base64) => [
      ...result,
      {
        types: groups[base64],
        base64: base64 === 'null' ? null : base64,
      },
    ],
    []
  )

  return setId
    ? imageData.map((data) =>
        getGroupedSetConfig(
          [data],
          additionalData,
          'patch',
          retailerId,
          shopId,
          setIndex
        )
      )
    : getGroupedSetConfig(
        imageData,
        additionalData,
        'post',
        retailerId,
        shopId,
        setIndex
      )
}

const getImageSetCreateOrDeleteConfig = ({
  set,
  setId,
  setIndex,
  retailerId,
  shopId,
}) =>
  setId
    ? {
        method: isEmpty(set) ? 'delete' : 'patch',
        url: `/${ENTITY_NAMES.images}/${setId}`,
        data: {
          data: {
            type: ENTITY_NAMES.images,
            id: setId,
            attributes: set,
          },
        },
        index: setIndex,
        errorMessage: isEmpty(set)
          ? `Images for the set ${setIndex + 1} haven't been updated`
          : `Image set ${setIndex + 1} hasn't been deleted`,
      }
    : {
        method: 'post',
        url: `/${ENTITY_NAMES.images}`,
        data: {
          data: {
            type: ENTITY_NAMES.images,
            attributes: {
              retailer_id: retailerId,
              shop_id: shopId?.toString(),
              ...set,
            },
          },
        },
        index: setIndex,
        errorMessage: `Images for the set ${setIndex + 1} haven't been saved`,
      }

const formatSetToSave = (set, gender) => [
  omitBy(
    {
      ...omit(set, [
        FIELD_NAMES.hidden,
        FIELD_NAMES.gender,
        FIELD_NAMES.deleted,
        FIELD_NAMES.isLocked,
      ]),
    },
    (data) => data === undefined
  ),
  set
    ? {
        [ENTITY_ATTRS[FIELD_NAMES.gender]]: gender,
        [ENTITY_ATTRS[FIELD_NAMES.hidden]]: set[FIELD_NAMES.hidden],
      }
    : null,
]

export const formatSetForRequest = ({
  set: data,
  index,
  gender,
  fullData,
  retailerId,
  shopId,
}) => {
  const [set, additional] = formatSetToSave(data, data?.gender && gender)
  const isEmptySet = isEmpty(set) || isEqual(set, EMPTY_IMAGE_SET)
  const isEmptyAttrs = isEmpty(additional)
  if (isEmptySet && !fullData?.id) return

  const canBeGrouped = !isEmptySet && hasSetTheSameImages(set)
  if (canBeGrouped) {
    return groupSetImages(
      set,
      fullData?.id,
      { ...additional },
      retailerId,
      shopId,
      index
    )
  }
  return [
    getImageSetCreateOrDeleteConfig({
      set: isEmptySet && isEmptyAttrs ? null : { ...set, ...additional },
      retailerId,
      shopId,
      setIndex: index,
      setId: fullData?.id,
    }),
  ]
}
