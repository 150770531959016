import moment from 'moment'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import { FIELD_NAMES } from 'const/forms'
import { ENTITY_ATTRS, RELATIONSHIPS_NAMES } from 'const/api'
import { DATE_FORMAT } from 'const/dates'
import { formatSetForRequest } from './requests'
import { IMAGE_GENDER_OPTIONS } from 'const/retailers'

export const formatImage = (image) => {
  if (!image) return

  return {
    id: image[FIELD_NAMES.id],
    manuallyUploaded: !!image[FIELD_NAMES.manuallyUploaded],
    ctr: image[FIELD_NAMES.ctr] || 0,
    createdAt: moment(image[FIELD_NAMES.createdAt]).format(DATE_FORMAT),
    value: image.src,
  }
}

export const formatImageInitialValue = (image) => (image ? image.src : null)

export const formatSet = (set, imageFormatter = formatImage) => ({
  [FIELD_NAMES.id]: set[FIELD_NAMES.id],
  [FIELD_NAMES.gender]: {
    value: set.attributes.gender,
    key: set.attributes.gender,
    label: IMAGE_GENDER_OPTIONS.find(
      (option) => option.value === set.attributes.gender
    )?.label,
  },
  [FIELD_NAMES.isLocked]: !!set.attributes[ENTITY_ATTRS[FIELD_NAMES.isLocked]],
  [FIELD_NAMES.hidden]: !!set.attributes[ENTITY_ATTRS[FIELD_NAMES.hidden]],
  [FIELD_NAMES.deleted]: false,
  [FIELD_NAMES.createdAt]: moment(set.attributes[FIELD_NAMES.createdAt]).format(
    DATE_FORMAT
  ),
  retailerId: set.attributes.retailer_id,
  [FIELD_NAMES.shopId]: set.attributes.shop_id,
  [FIELD_NAMES.headerWeb]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.headerWeb]
  ),
  [FIELD_NAMES.headerMobile]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.headerMobile]
  ),
  [FIELD_NAMES.cashbackWeb]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.cashbackWeb]
  ),
  [FIELD_NAMES.cashbackMobile]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.cashbackMobile]
  ),
  [FIELD_NAMES.featuredWeb]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.featuredWeb]
  ),
  [FIELD_NAMES.featuredMobile]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.featuredMobile]
  ),
  [FIELD_NAMES.trendingWeb]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.trendingWeb]
  ),
  [FIELD_NAMES.trendingMobile]: imageFormatter(
    set.attributes[RELATIONSHIPS_NAMES.imagesData][FIELD_NAMES.trendingMobile]
  ),
})

export const formatSetLogo = (retailer) =>
  retailer?.attributes?.[FIELD_NAMES.logo] || null

export const formatSetChangesForRequest = (data) => {
  const { fullData, values, retailerId, shopId } = data
  return reduce(
    values.set,
    (result, set, key) => {
      const oldValues = fullData?.[FIELD_NAMES.set]?.[key]
      return result.concat(
        formatSetForRequest({
          set,
          gender: get(set || oldValues, [FIELD_NAMES.gender, 'value']),
          index: ~~key,
          fullData: oldValues,
          retailerId,
          shopId,
        })
      )
    },
    []
  ).filter(Boolean)
}
