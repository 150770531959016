import React from 'react'
import identity from 'lodash/identity'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import { FormSpy } from 'react-final-form'
import { updateState } from 'reducers/forms/actions'
import useAction from 'utils/useAction'
import useFormShopImagesInitialize from './useFormShopImagesInitialize'

const FormShopImagesStateToRedux = ({
  form,
  formatter,
  onResetForm,
  onChangeFieldState,
}) => {
  const updateStateFunc = useAction(updateState)

  const handleChange = (formState) =>
    setTimeout(() =>
      updateStateFunc({
        form,
        formState: formatter(formState),
        onResetForm,
        onChangeFieldState,
      })
    )
  useFormShopImagesInitialize()

  return (
    <FormSpy
      subscription={{ dirtyFields: true, initialValues: true, values: true }}
      onChange={handleChange}
    />
  )
}

FormShopImagesStateToRedux.propTypes = {
  form: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  onResetForm: PropTypes.func,
  onChangeFieldState: PropTypes.func,
}

FormShopImagesStateToRedux.defaultProps = {
  formatter: identity,
  onResetForm: noop,
  onChangeFieldState: noop,
}

export default FormShopImagesStateToRedux
