import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Images = styled.div`
  display: flex;
  flex: 1;

  &:last-child {
    border-bottom: 1px dashed #b5b2b2;
  }

  .ant-upload-picture-card-wrapper {
    height: 104px;
    width: auto;
    .ant-upload-select-picture-card {
      margin: 0;
    }
  }
`

export const ImageWrapper = styled.div`
  line-height: 14px;
  font-size: 14px;
  min-width: 125px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: pre-wrap;
  .ant-upload-list-picture-card-container {
    margin: 0;
    .ant-upload-list-item-info::before {
      left: 0;
    }
  }

  ${({ isHidden }) => `
    background: ${isHidden ? '#f0f0f0' : 'white'};
  `}

  ${({ isDeleted }) => `
    opacity: ${isDeleted ? 0.2 : 1};
  `}
`
