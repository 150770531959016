import { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { MODAL_TYPES } from 'const/modals'
import { formValues } from 'reducers/forms/selectors'
import { FIELD_NAMES, FORM_NAMES } from 'const/forms'
import { diff } from 'deep-object-diff'
import useAction from 'utils/useAction'
import {
  getInitialValuesShopImages,
  getShopImageSetDeletedSets,
} from 'domains/shops/selectors'
import { setShopImageSetsDeletedSets } from 'domains/shops/actions'

const useModalShopImagesCancel = (type, closeHandler, openModal) => {
  const initialValues = useSelector(getInitialValuesShopImages)
  const values = useSelector(formValues)(FORM_NAMES.IMAGES)
  const deletedSets = useSelector(getShopImageSetDeletedSets)
  const setShopImageSetsDeletedSetsFunc = useAction(setShopImageSetsDeletedSets)
  const skipChanges = () => {
    closeHandler()
    setShopImageSetsDeletedSetsFunc([])
  }

  const isChangesEmpty = useCallback(() => {
    const filteredValues = {
      ...values,
      [FIELD_NAMES.set]:
        values?.[FIELD_NAMES.set]?.map((set, index) =>
          deletedSets.includes(index) ? null : set
        ) || [],
    }
    return isEmpty(diff(initialValues, filteredValues))
  }, [deletedSets, initialValues, values])

  if (type === MODAL_TYPES.SHOP_IMAGES) {
    if (!isChangesEmpty()) {
      return [openModal, skipChanges]
    } else {
      return [closeHandler]
    }
  } else {
    return [closeHandler]
  }
}

export default useModalShopImagesCancel
