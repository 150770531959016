import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { EMPTY_IMAGE_SET, LOGO, MAX_NUMBER_OF_SET } from 'const/images'
import { FIELD_NAMES } from 'const/forms'
import { setFullPropType, setPropType } from 'const/propTypes'
import {
  AddButton,
  Content,
  Images,
  Text,
  Header,
  Name,
  Names,
} from './Sets.styles'
import ImageSet from './ImageSet'
import SetInfo from './SetInfo'
import { Field } from 'react-final-form'
import ImagePicker from 'components/ImagePicker'
import { Tooltip } from 'antd'
import { useSelector } from 'react-redux'
import { selectors as generalSelectors } from 'domains/general'
import useAction from 'utils/useAction'
import {
  getIsSavingActive,
  getIsShopImageSetLoaded,
  getShopImageSetDeletedSets,
} from 'domains/shops/selectors'
import { setShopImageSetsDeletedSets } from 'domains/shops/actions'

const addRecord = (push) => () => push(EMPTY_IMAGE_SET)

const Sets = ({ fullData, showInfo, values }) => {
  const imageSets = useSelector(
    generalSelectors.getSortedImagesByGroupPositions
  )
  const isLoaded = useSelector(getIsShopImageSetLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const deletedSets = useSelector(getShopImageSetDeletedSets)
  const setShopImageSetsDeletedSetsFunc = useAction(setShopImageSetsDeletedSets)

  const deleteHandler = useCallback(
    (fields, index) => () => {
      if (!fullData?.[index]) {
        fields.remove(index)
      } else {
        fields.update(index, {
          ...fields.value[index],
          [FIELD_NAMES.deleted]: true,
        })
        setShopImageSetsDeletedSetsFunc([...deletedSets, index])
      }
    },
    [deletedSets, fullData, setShopImageSetsDeletedSetsFunc]
  )

  const restoreHandler = (fields, index) => () => {
    setShopImageSetsDeletedSetsFunc(
      deletedSets.filter((item) => item !== index)
    )
    fields.update(index, {
      ...fields.value[index],
      [FIELD_NAMES.deleted]: false,
    })
  }

  return (
    <FieldArray name={FIELD_NAMES.set}>
      {({ fields }) => (
        <>
          <Header>
            <Field
              name={LOGO.name}
              cropSize={LOGO.cropSize}
              forceInvalid
              component={ImagePicker}
              hasCropper
            />
          </Header>
          {!showInfo ? (
            <>
              <Content>
                <Images>
                  {(isLoaded || isSavingActive) && (
                    <Names>
                      {imageSets.map((image) => (
                        <Tooltip key={image.name} title={image.tooltip}>
                          <Name>{image.label}</Name>
                        </Tooltip>
                      ))}
                    </Names>
                  )}
                  {fields.map((name, index) => {
                    const isDeleted = deletedSets.includes(index)
                    const isLocked = !!fullData?.[index]?.[FIELD_NAMES.isLocked]
                    return (
                      <ImageSet
                        isHidden={
                          values[FIELD_NAMES.set][index]?.[FIELD_NAMES.hidden]
                        }
                        isDeleted={isDeleted}
                        isLocked={isLocked}
                        fullData={fullData?.[index]}
                        name={name}
                        key={name}
                      >
                        <SetInfo
                          isNullable={!values[FIELD_NAMES.set][index]}
                          isDeleted={isDeleted}
                          isLocked={isLocked}
                          fieldName={name}
                          setId={index}
                          deleteHandler={deleteHandler(fields, index)}
                          restoreHandler={restoreHandler(fields, index)}
                          fullData={fullData}
                        />
                      </ImageSet>
                    )
                  })}
                </Images>
              </Content>
            </>
          ) : (
            <Text>
              Please create at least one image set for each gender to save
              changes
            </Text>
          )}
          {fields.length - deletedSets.length < MAX_NUMBER_OF_SET && (
            <AddButton onClick={addRecord(fields.push)} />
          )}
        </>
      )}
    </FieldArray>
  )
}

Sets.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  showInfo: PropTypes.bool,
  // eslint-disable-next-line react/boolean-prop-naming
  fullData: PropTypes.arrayOf(setFullPropType),
  values: PropTypes.shape({
    [FIELD_NAMES.set]: PropTypes.arrayOf(setPropType),
  }),
}

export default Sets
