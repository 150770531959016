import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { useSelector } from 'react-redux'
import { diff } from 'deep-object-diff'
import isEmpty from 'lodash/isEmpty'
import { setPropType, setFullPropType } from 'const/propTypes'
import Sets from './Sets'
import { FIELD_NAMES } from 'const/forms'
import { Tooltip } from 'antd'
import { hasAllGenders, getErrorText } from 'helpers/images'
import {
  getIsSavingActive,
  getIsShopImageSetLoaded,
  getShopImageSetDeletedSets,
} from 'domains/shops/selectors'
import { Buttons, StyledButton } from './ImagesForm.styles'
import FormShopImagesStateToRedux from 'components/FormShopImagesStateToRedux '

const ImagesForm = ({
  formName,
  closeModal,
  onSubmit,
  initialValues,
  fullData,
}) => {
  const isSavingActive = useSelector(getIsSavingActive)
  const isLoaded = useSelector(getIsShopImageSetLoaded)
  const deletedFields = useSelector(getShopImageSetDeletedSets)

  return (
    <Form
      destroyOnUnregister
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        hasValidationErrors,
        values,
        form: { change },
      }) => {
        const filteredValues = {
          ...values,
          [FIELD_NAMES.set]:
            values[FIELD_NAMES.set]?.map((set, index) =>
              deletedFields.includes(index) ? null : set
            ) || [],
        }
        const hasSet = !!(
          values[FIELD_NAMES.set]?.length - deletedFields.length
        )
        const isEmptyChanges = isEmpty(diff(initialValues, filteredValues))

        const disableSaveButton =
          hasValidationErrors || isSavingActive || isEmptyChanges || !isLoaded

        return (
          <>
            <form>
              <FormShopImagesStateToRedux
                form={formName}
                onChangeFieldState={change}
              />
              <Sets
                showInfo={
                  !hasSet && isLoaded && !initialValues[FIELD_NAMES.set]?.length
                }
                values={values}
                fullData={fullData?.[FIELD_NAMES.set]}
              />
              <Buttons>
                <StyledButton onClick={closeModal}>Cancel</StyledButton>
                <Tooltip
                  title={getErrorText({
                    hasAllGenders: hasAllGenders(filteredValues),
                    hasSet,
                    hasValidationErrors,
                    isEmptyChanges,
                  })}
                >
                  <StyledButton
                    type="primary"
                    onClick={handleSubmit}
                    disabled={disableSaveButton}
                  >
                    Save changes
                  </StyledButton>
                </Tooltip>
              </Buttons>
            </form>
          </>
        )
      }}
    />
  )
}

ImagesForm.propTypes = {
  formName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    [FIELD_NAMES.logo]: PropTypes.string,
    [FIELD_NAMES.set]: PropTypes.arrayOf(setPropType),
  }),
  fullData: PropTypes.shape({
    [FIELD_NAMES.logo]: PropTypes.string,
    [FIELD_NAMES.set]: PropTypes.arrayOf(setFullPropType),
  }),
}

export default ImagesForm
