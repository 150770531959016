import { useEffect } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { getFormSavedValues } from 'reducers/forms/selectors'
import { resetValues } from 'reducers/forms/actions'
import useAction from 'utils/useAction'
import {
  getIsShopImageSetLoaded,
  getShopImageSetDirtyFields,
} from 'domains/shops/selectors'

const useFormShopImagesInitialize = () => {
  const resetValuesFunc = useAction(resetValues)
  const form = useForm()
  const { batch, change, getState } = form
  const savedValues = useSelector(getFormSavedValues)
  const dirtyFields = useSelector(getShopImageSetDirtyFields)
  const isLoaded = useSelector(getIsShopImageSetLoaded)
  const { initialValues } = getState()

  useEffect(() => {
    if (isLoaded && !isEmpty(savedValues)) {
      batch(() => {
        Object.keys(dirtyFields).forEach((key) => {
          if (
            get(savedValues, key) !== undefined &&
            get(initialValues, key) !== undefined
          ) {
            change(key, get(savedValues, key))
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch, change, savedValues, dirtyFields, isLoaded])

  useEffect(() => {
    return () => {
      resetValuesFunc()
    }
  }, [resetValuesFunc])
}

export default useFormShopImagesInitialize
