import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { diff } from 'deep-object-diff'
import { Layout, Spin } from 'antd'
import useAction from 'utils/useAction'
import { FIELD_NAMES, FORM_NAMES } from 'const/forms'
import ImagesForm from './ImagesForm'
import {
  getCurrentShopId,
  getInitialValuesShopImages,
  getIsSavingActive,
  getIsShopImageSetLoaded,
  getShopImageSetDeletedSets,
  getShopImageSets,
} from 'domains/shops/selectors'
import { fetchShopImageSets } from 'actions/views/shops'
import { resetShopImageSets, updateShopImageSets } from 'domains/shops/actions'

const ShopImages = ({ closeModal }) => {
  const currentShopId = useSelector(getCurrentShopId)
  const deletedSets = useSelector(getShopImageSetDeletedSets)
  const loaded = useSelector(getIsShopImageSetLoaded)
  const isSavingActive = useSelector(getIsSavingActive)
  const initialValues = useSelector(getInitialValuesShopImages)
  const fullData = useSelector(getShopImageSets)

  const fetchShopImageSetsFunc = useAction(fetchShopImageSets)
  const resetShopImageSetsFunc = useAction(resetShopImageSets)
  const updateShopImageSetsFunc = useAction(updateShopImageSets)

  const onSubmit = (submittedValues) => {
    const filteredValues = {
      ...submittedValues,
      [FIELD_NAMES.set]:
        submittedValues[FIELD_NAMES.set]?.map((set, index) =>
          deletedSets.includes(index) ? null : set
        ) || [],
    }

    const values = diff(initialValues, filteredValues)
    updateShopImageSetsFunc({
      shopId: currentShopId,
      fullData,
      values,
    })
  }

  useEffect(() => {
    fetchShopImageSetsFunc()

    return () => {
      resetShopImageSetsFunc()
    }
  }, [])

  return (
    <Layout.Content>
      <Spin spinning={!loaded || isSavingActive} size="large">
        <ImagesForm
          formName={FORM_NAMES.IMAGES}
          closeModal={closeModal}
          initialValues={initialValues}
          fullData={fullData}
          onSubmit={onSubmit}
        />
      </Spin>
    </Layout.Content>
  )
}

ShopImages.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default ShopImages
