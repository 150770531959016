import styled from 'styled-components'
import { PlusCircleOutlined } from '@ant-design/icons'

export const AddButton = styled(PlusCircleOutlined)`
  width: 100%;
  font-size: 24px;
  margin-top: 20px;

  &:hover {
    color: #1890ff;
  }
`

export const Content = styled.div`
  display: flex;
`

export const Images = styled.div`
  flex: 1;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-right: 125px;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const Text = styled.span`
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding: 40px 0;
  display: block;
`

export const Header = styled.div`
  display: flex;
`

export const Name = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  width: 104px;
  min-height: 28px;
  text-align: center;
  min-width: 125px;
  max-width: 125px;
  white-space: pre-wrap;
  line-height: 14px;
`

export const Names = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
`
