import { createSelector } from '@reduxjs/toolkit'
import { formatCampaigns } from './formatters'
import memoize from 'lodash/memoize'
import { SHOP_SUB_ENTITIES } from './const'
import { areFiltersAndSorterEmpty } from '../../helpers/filters'
import {
  formatImageInitialValue,
  formatSet,
  formatSetLogo,
} from 'helpers/formatters/images'
import { FIELD_NAMES } from 'const/forms'

const getShopsState = (store) => store.shops

export const getIsSavingActive = createSelector(
  getShopsState,
  ({ isSavingActive }) => isSavingActive
)

export const getShopsGroup = createSelector(
  getShopsState,
  ({ shops }) => shops || {}
)

export const getShops = createSelector(getShopsGroup, ({ data }) => data || [])

export const getSearchString = createSelector(
  getShopsGroup,
  ({ search }) => search
)

export const getCurrentShop = createSelector(
  getShopsGroup,
  ({ currentShop }) => currentShop
)

export const getIsCurrentShopAvailable = createSelector(
  getCurrentShop,
  (currentShop) => !!currentShop
)

export const getCurrentShopAttributes = createSelector(
  getCurrentShop,
  (shop) => shop?.attributes || {}
)

export const getCurrentShopGroups = createSelector(
  getCurrentShop,
  (shop) => shop?.shop_groups || []
)

export const getCurrentShopGroupsAttributesAndId = createSelector(
  getCurrentShopGroups,
  (shopGroups) =>
    shopGroups.map((group) => ({
      shop_group_id: group.id,
      ...group.attributes,
    }))
)

export const getCurrentShopCategories = createSelector(
  getCurrentShop,
  (shop) =>
    shop?.shop_categories?.map((category) => ({
      ...category,
      category_id: String(category.category_id),
    })) || []
)

export const getCurrentShopMainCategory = createSelector(
  getCurrentShopCategories,
  (categories) => categories.find((category) => category?.attributes?.main)
)

export const getCurrentShopRegularCategories = createSelector(
  getCurrentShopCategories,
  (categories) => categories.filter((category) => !category?.attributes?.main)
)

export const getCurrentShopId = createSelector(
  getCurrentShop,
  (shop) => shop?.id || ''
)

// LOCALIZED SHOPS

export const getLocalizedShopsGroup = createSelector(
  getShopsState,
  (shops) => shops?.[SHOP_SUB_ENTITIES.localizedShops]
)

export const getLocalizedShops = createSelector(
  getLocalizedShopsGroup,
  ({ data }) => data || []
)

export const getLocalizedShopById = createSelector(
  getLocalizedShops,
  (localizedShops) =>
    memoize((id) =>
      id
        ? localizedShops?.find((item) => parseInt(item.id) === parseInt(id))
        : null
    )
)

export const getLocalizedShopsCountryCodes = createSelector(
  getCurrentShop,
  (currentShop) => currentShop?.attributes?.localized_shops_country_codes
)

export const getIsLocalizedShopsLoaded = createSelector(
  getLocalizedShopsGroup,
  ({ isLoaded }) => isLoaded
)

export const getLocalizedShopsPage = createSelector(
  getLocalizedShopsGroup,
  ({ page }) => page || 1
)

export const getLocalizedShopsPageCount = createSelector(
  getLocalizedShopsGroup,
  ({ pageCount }) => pageCount || 1
)

export const getLocalizedShopsFilters = createSelector(
  getLocalizedShopsGroup,
  ({ filters }) => filters || {}
)

export const getLocalizedShopsSorter = createSelector(
  getLocalizedShopsGroup,
  ({ sorter }) => sorter
)

export const getIsLocalizedShopsDataFilteredOrSorted = createSelector(
  [getLocalizedShopsFilters, getLocalizedShopsSorter],
  areFiltersAndSorterEmpty
)

// CAMPAIGN DEALS

export const getShopCampaignDealsGroup = createSelector(
  getShopsState,
  (shops) => shops?.[SHOP_SUB_ENTITIES.campaignDeals]
)

export const getShopCampaignDeals = createSelector(
  getShopCampaignDealsGroup,
  ({ data }) => data
)

export const getShopCampaignDealsById = createSelector(
  getShopCampaignDeals,
  (campaignDeals) => memoize((id) => formatCampaigns(campaignDeals)[id])
)

export const getIsCampaignDealsLoaded = createSelector(
  getShopCampaignDealsGroup,
  ({ isLoaded }) => isLoaded || false
)

export const getCampaignDealsFilters = createSelector(
  getShopCampaignDealsGroup,
  ({ filters }) => filters || {}
)

export const getCampaignDealsSorter = createSelector(
  getShopCampaignDealsGroup,
  ({ sorter }) => sorter || {}
)

export const isDataCampaignDealsFilteredOrSorted = createSelector(
  [getCampaignDealsFilters, getCampaignDealsSorter],
  areFiltersAndSorterEmpty
)

export const getCampaignDealsPage = createSelector(
  getShopCampaignDealsGroup,
  ({ page }) => page || 1
)

export const getCampaignDealsPageCount = createSelector(
  getShopCampaignDealsGroup,
  ({ pageCount }) => pageCount || 1
)

// AD PACKAGES

export const getShopAdPackagesGroup = createSelector(
  getShopsState,
  (shops) => shops?.[SHOP_SUB_ENTITIES.adPackages]
)

export const getShopAdPackages = createSelector(
  getShopAdPackagesGroup,
  ({ data }) => data || []
)

export const getShopProgramsGroup = createSelector(
  getShopsState,
  (shops) => shops?.[SHOP_SUB_ENTITIES.programs]
)

const getShopImageSetState = createSelector(
  getShopsState,
  (shops) => shops?.[SHOP_SUB_ENTITIES.imageSets]
)

export const getShopAdPackageById = createSelector(
  getShopAdPackages,
  (adPackages) =>
    memoize((id) => adPackages.find((adPackage) => adPackage.id === id) || {})
)

export const getIsAdPackagesLoaded = createSelector(
  getShopAdPackagesGroup,
  ({ isLoaded }) => isLoaded || false
)

export const getAdPackagesPage = createSelector(
  getShopAdPackagesGroup,
  ({ page }) => page || 1
)

export const getAdPackagesPageCount = createSelector(
  getShopAdPackagesGroup,
  ({ pageCount }) => pageCount || 1
)

export const getAdPackagesFilters = createSelector(
  getShopAdPackagesGroup,
  ({ filters }) => filters || {}
)

export const getAdPackagesSorter = createSelector(
  getShopAdPackagesGroup,
  ({ sorter }) => sorter
)

export const getIsAdPackagesDataFilteredOrSorted = createSelector(
  [getAdPackagesFilters, getAdPackagesSorter],
  areFiltersAndSorterEmpty
)

// OFFERS

export const getShopOffersGroup = createSelector(
  getShopsState,
  (shops) => shops?.[SHOP_SUB_ENTITIES.shopOffers]
)

export const getShopOffers = createSelector(
  getShopOffersGroup,
  ({ data }) => data || []
)

export const getShopOffersById = createSelector(getShopOffers, (shopOffers) =>
  memoize((id) => shopOffers?.find((offer) => offer?.id === id) || {})
)

export const getIsShopOffersLoaded = createSelector(
  getShopOffersGroup,
  ({ isLoaded }) => isLoaded
)

export const getShopOffersFilters = createSelector(
  getShopOffersGroup,
  ({ filters }) => filters || {}
)

export const getShopOffersSorter = createSelector(
  getShopOffersGroup,
  ({ sorter }) => sorter || {}
)

export const getIsShopOffersDataFilteredOrSorted = createSelector(
  [getShopOffersFilters, getShopOffersSorter],
  areFiltersAndSorterEmpty
)

export const getShopOffersPage = createSelector(
  getShopOffersGroup,
  ({ page }) => page || 1
)

export const getShopOffersPageCount = createSelector(
  getShopOffersGroup,
  ({ pageCount }) => pageCount || 1
)

export const getIsProgramsLoaded = createSelector(
  getShopProgramsGroup,
  ({ isLoaded }) => isLoaded
)

export const getShopPrograms = createSelector(
  getShopProgramsGroup,
  ({ data }) => data || []
)

// IMAGE SETS
export const getShopImageSetDirtyFields = createSelector(
  getShopImageSetState,
  ({ dirtyFields }) => dirtyFields
)

export const getShopImageSetDeletedSets = createSelector(
  getShopImageSetState,
  ({ deletedSets }) => deletedSets
)

export const getIsShopImageSetLoaded = createSelector(
  getShopImageSetState,
  ({ isLoaded }) => isLoaded
)

const formatSetsData = (currentShop, imageSets = [], imageFormatter) => {
  return imageSets.reduce(
    (result, set) => {
      const item = formatSet(set, imageFormatter)
      if (result[FIELD_NAMES.set] && item) {
        result[FIELD_NAMES.set].push(item)
      } else {
        result[FIELD_NAMES.set] = [item]
      }
      return result
    },
    {
      [FIELD_NAMES.logo]: formatSetLogo(currentShop, imageFormatter),
      [FIELD_NAMES.set]: [],
    }
  )
}

export const getInitialValuesShopImages = createSelector(
  getShopsState,
  (shops) =>
    formatSetsData(
      shops?.shops?.currentShop,
      shops?.[SHOP_SUB_ENTITIES.imageSets]?.data || [],
      formatImageInitialValue
    )
)

export const getShopImageSets = createSelector(getShopsState, (shops) =>
  formatSetsData(
    shops?.currentShop,
    shops?.[SHOP_SUB_ENTITIES.imageSets]?.data || []
  )
)
