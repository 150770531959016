import { createAction } from '@reduxjs/toolkit'
import reducer from './reducer'

export const {
  saveFiltersAndSorter,
  setSearch,
  fetchShopsSuccess,
  fetchShopsFailure,
  fetchLocalizedShopsSuccess,
  fetchLocalizedShopsFailure,
  fetchShopImageSetsSuccess,
  fetchShopImageSetsFailure,
  fetchShopCampaignDealsSuccess,
  fetchShopCampaignDealsFailure,
  fetchShopAdPackagesSuccess,
  fetchShopAdPackagesFailure,
  fetchShopOffersSuccess,
  fetchShopOffersFailure,
  fetchShopProgramsSuccess,
  fetchShopProgramsFailure,
  fetchShopsToVerifySuccess,
  fetchShopsToVerifyFailure,
  startSaving,
  finishSaving,
  clearShops,
  clearData,
  removeShopGroup,
  addShopGroup,
  updateShopGroupPosition,
  setShopImageSetsDeletedSets,
  resetShopImageSets,
} = reducer.actions

export const fetchShops = createAction('shops/fetchShops')
export const fetchLocalizedShops = createAction('shops/fetchLocalizedShops')
export const fetchShopCampaignDeals = createAction(
  'shops/fetchShopCampaignDeals'
)
export const fetchShopAdPackages = createAction('shops/fetchShopAdPackages')
export const fetchShopOffers = createAction('shops/fetchShopOffers')

export const fetchShopPrograms = createAction('shops/fetchShopPrograms')

export const updateShop = createAction('shops/updateShop')
export const updateShopSuccess = createAction('shops/updateShopsSuccess')
export const updateShopFailure = createAction('shops/updateShopFailure')

export const updateLocalizedShop = createAction('shops/updateLocalizedShop')
export const updateLocalizedShopSuccess = createAction(
  'shops/updateLocalizedShopsSuccess'
)
export const updateLocalizedShopFailure = createAction(
  'shops/updateLocalizedShopFailure'
)

export const updateShopCouponsSettings = createAction(
  'shops/updateShopCouponsSettings'
)
export const updateShopCouponsSettingsSuccess = createAction(
  'shops/updateShopCouponsSettingsSuccess'
)
export const updateShopCouponsSettingsFailure = createAction(
  'shops/updateShopCouponsSettingsFailure'
)

export const createShopCampaign = createAction('shops/campaigns/createCampaign')

export const createShopCampaignSuccess = createAction(
  'shops/campaigns/createShopCampaignSuccess'
)

export const createShopCampaignFailure = createAction(
  'shops/campaigns/createShopCampaignFailure'
)

export const updateShopCampaign = createAction(
  'shops/campaigns/updateShopCampaign'
)
export const updateShopCampaignSuccess = createAction(
  'shops/campaigns/updateShopCampaignSuccess'
)
export const updateShopCampaignFailure = createAction(
  'shops/campaigns/updateShopCampaignFailure'
)

export const deleteCampaign = createAction('shops/campaigns/deleteCampaign')
export const deleteCampaignSuccess = createAction(
  'shops/campaigns/deleteCampaignSuccess'
)
export const deleteCampaignFailure = createAction(
  'shops/campaigns/deleteCampaignFailure'
)

export const createShopOffer = createAction('shops/offers/createShopOffer')
export const createShopOfferSuccess = createAction(
  'shops/offers/createShopOfferSuccess'
)
export const createShopOfferFailure = createAction(
  'shops/offers/createShopOfferFailure'
)

export const updateShopOffer = createAction('shops/offers/updateShopOffer')
export const updateShopOfferSuccess = createAction(
  'shops/offers/updateShopOfferSuccess'
)
export const updateShopOfferFailure = createAction(
  'shops/offers/updateShopOfferFailure'
)

export const deleteAdPackage = createAction('shops/adPackages/deleteAdPackage')
export const deleteAdPackageSuccess = createAction(
  'shops/adPackages/deleteAdPackageSuccess'
)
export const deleteAdPackageFailure = createAction(
  'shops/adPackages/deleteAdPackageFailure'
)

export const duplicateAdPackage = createAction(
  'shops/adPackages/duplicateAdPackage'
)
export const duplicateAdPackageSuccess = createAction(
  'shops/adPackages/duplicateAdPackageSuccess'
)
export const duplicateAdPackageFailure = createAction(
  'shops/adPackages/duplicateAdPackageFailure'
)

export const deleteShopOffer = createAction('shops/offers/deleteShopOffer')
export const deleteShopOfferSuccess = createAction(
  'shops/offers/deleteShopOfferSuccess'
)
export const deleteShopOfferFailure = createAction(
  'shops/offers/deleteShopOfferFailure'
)

export const createShopGroup = createAction('shops/groups/createShopGroup')
export const createShopGroupSuccess = createAction(
  'shops/groups/createShopGroupSuccess'
)
export const createShopGroupFailure = createAction(
  'shops/groups/createShopGroupFailure'
)

export const updateShopGroup = createAction('shops/groups/updateShopGroup')
export const updateShopGroupSuccess = createAction(
  'shops/groups/updateShopGroupSuccess'
)
export const updateShopGroupFailure = createAction(
  'shops/groups/updateShopGroupFailure'
)

export const deleteShopGroup = createAction('shops/groups/deleteShopGroup')
export const deleteShopGroupSuccess = createAction(
  'shops/groups/deleteShopGroupSuccess'
)
export const deleteShopGroupFailure = createAction(
  'shops/groups/deleteShopGroupFailure'
)

export const createShopCategory = createAction(
  'shops/categories/createShopCategory'
)
export const createShopCategorySuccess = createAction(
  'shops/categories/createShopCategorySuccess'
)
export const createShopCategoryFailure = createAction(
  'shops/categories/createShopCategoryFailure'
)

export const updateShopCategory = createAction(
  'shops/categories/updateShopCategory'
)
export const updateShopCategorySuccess = createAction(
  'shops/categories/updateShopCategorySuccess'
)
export const updateShopCategoryFailure = createAction(
  'shops/categories/updateShopCategoryFailure'
)

export const deleteShopCategory = createAction(
  'shops/categories/deleteShopCategory'
)
export const deleteShopCategorySuccess = createAction(
  'shops/categories/deleteShopCategorySuccess'
)
export const deleteShopCategoryFailure = createAction(
  'shops/categories/deleteShopCategoryFailure'
)

// Shop image set
export const fetchShopImageSets = createAction('shops/fetchShopImageSets')

export const updateShopImageSets = createAction(
  'shops/imageSet/updateShopImageSets'
)
