import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createShopCampaign, updateShopCampaign } from 'actions/views/shops'
import useAction from 'utils/useAction'
import arrayMutators from 'final-form-arrays'
import { Spin } from 'antd'
import { Field, Form } from 'react-final-form'
import Input from 'components/Input/Input'
import InputNumber from 'components/Input/InputNumber'
import DatePicker from 'components/DatePicker/DatePicker'
import { FIELD_NAMES } from 'const/forms'
import { required, requiredNumber } from 'helpers/validators'
import { StyledButton, StyledForm } from './Campaign.styles'
import Select from 'components/Select/Select'
import { CAMPAIGN_TYPES } from './const'
import FieldSwitch from 'components/FieldSwitch/FieldSwitch'
import SearchNetwork from './SearchNetwork'
import TextArea from 'components/TextArea/TextArea'
import SearchRule from './SearchRule'
import {
  getCurrentShopId,
  getIsSavingActive,
  getShopCampaignDealsById,
} from 'domains/shops/selectors'
import { getLocalizedShops } from 'domains/shops/api'

const ShopCampaign = ({ campaignId, closeModal }) => {
  const isSavingActive = useSelector(getIsSavingActive)
  const initialValues = useSelector(getShopCampaignDealsById)(campaignId)
  const shopId = useSelector(getCurrentShopId)
  const createCampaignFunc = useAction(createShopCampaign)
  const updateCampaignFunc = useAction(updateShopCampaign)

  const [currentShopLocalizedShops, setCurrentShopLocalizedShops] = useState([])
  const [initRules, setInitRules] = useState([])

  const retailerSelectOptions = useMemo(
    () =>
      uniqBy(
        currentShopLocalizedShops,
        (shop) => shop.attributes.rule_id
      ).map((shop) => ({
        value: shop.attributes.rule_id,
        label: `${shop.attributes.domain} - ${shop.attributes.country_code}`,
      })),
    [currentShopLocalizedShops]
  )


  const onSubmit = (data) => {
    const values = {
      ...data
    }

    if (
      values[FIELD_NAMES.dealType] === 'flat fee' ||
      values[FIELD_NAMES.dealType] === 'fixed fee'
    ) {
      values[FIELD_NAMES.currentCpaPercentage] = null
      values[FIELD_NAMES.newCpaPercentage] = null
    }

    if (
      values[FIELD_NAMES.dealType] === 'flat fee' ||
      values[FIELD_NAMES.dealType] === 'hybrid'
    ) {
      values[FIELD_NAMES.fixedFee] = null
      values[FIELD_NAMES.fixedFeeCurrency] = null
    }

    if (values[FIELD_NAMES.dealType] === 'fixed fee') {
      values[FIELD_NAMES.flatFee] = null
      values[FIELD_NAMES.flatFeeCurrency] = null
    }

    if (values[FIELD_NAMES.dealType] === 'cpa') {
      values[FIELD_NAMES.flatFee] = null
      values[FIELD_NAMES.flatFeeCurrency] = null
      values[FIELD_NAMES.fixedFee] = null
      values[FIELD_NAMES.fixedFeeCurrency] = null
    }

    values[FIELD_NAMES.shopId] = shopId
    values[FIELD_NAMES.ruleIds] = data[FIELD_NAMES.ruleIds].join(',')

    if (campaignId) {
      updateCampaignFunc({ data: values, callback: closeModal })
    } else {
      createCampaignFunc({ data: values, callback: closeModal })
    }
  }

  useEffect(() => {
    const loadShopRetailers = async () => {
      const { data } =
        (await getLocalizedShops({ search: shopId, perPage: 150 })) || {}

      if (data?.included) {
        setCurrentShopLocalizedShops(data.included)
        let rules = []
        if (campaignId) {
          rules = data.included.filter(
            (item) =>
              initialValues[FIELD_NAMES.ruleIds].includes(
                item.attributes?.rule_id.toString()
              )
          ).map((item) => item.attributes.rule_id)
        }
        setInitRules(rules)
      }
    }

    loadShopRetailers()
  }, [])

  return (
    <Form
      destroyOnUnregister
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => {
        return (
          <Spin spinning={isSavingActive} size="large">
            <StyledForm onSubmit={handleSubmit}>
              {campaignId && (
                <Field
                  name={FIELD_NAMES.id}
                  label="Campaign id"
                  disabled
                  component={Input}
                />
              )}

              <SearchRule options={retailerSelectOptions} initialValues={initRules} />

              <SearchNetwork
                required
                name={FIELD_NAMES.network}
                label="Network"
                placeholder="Network"
                component={Input}
                validate={required}
              />

              <Field
                required
                name={FIELD_NAMES.dealType}
                options={CAMPAIGN_TYPES}
                label="Type"
                component={Select}
                validate={required}
              />

              {(values[FIELD_NAMES.dealType] === 'flat fee' ||
                values[FIELD_NAMES.dealType] === 'hybrid') && (
                  <>
                    <Field
                      required
                      name={FIELD_NAMES.flatFee}
                      label="Flat Fee"
                      placeholder="%"
                      component={InputNumber}
                      validate={requiredNumber}
                    />

                    <Field
                      required
                      name={FIELD_NAMES.flatFeeCurrency}
                      label="Flat Fee Currency"
                      placeholder="Ex: GBT"
                      component={Input}
                      validate={required}
                    />
                  </>
                )}

              {values[FIELD_NAMES.dealType] === 'fixed fee' && (
                <>
                  <Field
                    required
                    name={FIELD_NAMES.fixedFee}
                    label="Fixed Fee"
                    placeholder="number"
                    component={InputNumber}
                    validate={required}
                  />

                  <Field
                    required
                    name={FIELD_NAMES.fixedFeeCurrency}
                    label="Fixed Fee Currency"
                    placeholder="Ex: GBT"
                    component={Input}
                    validate={required}
                  />
                </>
              )}

              {values[FIELD_NAMES.dealType] === 'flat fee' ||
                values[FIELD_NAMES.dealType] === 'fixed fee' ? null : (
                <>
                  <Field
                    required
                    name={FIELD_NAMES.currentCpaPercentage}
                    label="Existing CPA"
                    placeholder="%"
                    component={InputNumber}
                    validate={required}
                  />

                  <Field
                    required
                    validate={required}
                    name={FIELD_NAMES.newCpaPercentage}
                    label="New Cpa"
                    placeholder="%"
                    component={InputNumber}
                  />
                </>
              )}
              <Field
                required
                name={FIELD_NAMES.startDate}
                label="Start Date"
                component={DatePicker}
              />

              <Field
                required
                name={FIELD_NAMES.endDate}
                label="End Date"
                component={DatePicker}
              />

              {values[FIELD_NAMES.notifySlack] ? (
                <Field
                  required
                  validate={required}
                  name={FIELD_NAMES.freeTextSlack}
                  label="Free Text"
                  placeholder=""
                  component={TextArea}
                />
              ) : null}

              <Field
                type="checkbox"
                name={FIELD_NAMES.notifySlack}
                component={FieldSwitch}
                label="Notify"
                defaultValue={false}
              />

              <Field
                required
                name={FIELD_NAMES.targetUrl}
                label="Target URL"
                component={Input}
              />

              {!values[FIELD_NAMES.startDate] ||
                !values[FIELD_NAMES.endDate] ? (
                <StyledButton
                  type="primary"
                  disabled={true}
                  onClick={handleSubmit}
                >
                  Save changes
                </StyledButton>
              ) : (
                <StyledButton
                  type="primary"
                  disabled={pristine}
                  onClick={handleSubmit}
                >
                  Save changes
                </StyledButton>
              )}
            </StyledForm>
          </Spin>
        )
      }}
    />
  )
}

ShopCampaign.propTypes = {
  campaignId: PropTypes.number,
  closeModal: PropTypes.func.isRequired,
}

ShopCampaign.defaultTypes = {
  campaignId: null,
}

export default ShopCampaign
