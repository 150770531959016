import React from 'react'
import { Field } from 'react-final-form'
import FieldWrapper from 'components/FieldWrapper/FieldWrapper.js'
import { requiredNested } from 'helpers/validators'
import { FIELD_NAMES } from 'const/forms'

import { StyledSelect } from './SearchRule.styles'

const SearchRule = ({ options, initialValues }) => {

  return (
    <Field name={FIELD_NAMES.ruleIds} validate={requiredNested} initialValue={initialValues}>
      {(props) => (
        <>
          <FieldWrapper label="Rule" meta={props.meta} required>
            <StyledSelect
              invalid={props.meta.touched && props.meta.error}
              name={FIELD_NAMES.ruleIds}
              labelInValue
              mode="multiple"
              options={options}
              value={props.input.value || undefined}
              showSearch
              placeholder="Search Rules"
              filterOption={(input, option) => {
                if (!input || !option) return true
                const optionLabel = option.children || option.label || ''
                return optionLabel
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              onChange={(value) => {
                props.input.onChange(value?.map((item) => item.value) || [])
              }}
            />
          </FieldWrapper>
        </>
      )}
    </Field>
  )
}

export default SearchRule
