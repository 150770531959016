import React from 'react'
import PropTypes from 'prop-types'
import { RestoreButton, DeleteButton } from './DeleteSwitchByValue.styles'
import { Tooltip } from 'antd'

const DeleteSwitchByValue = ({
  disabled,
  isDeleted,
  restoreHandler,
  deleteHandler,
}) => {
  const Icon = isDeleted ? RestoreButton : DeleteButton
  const handleChange = () => {
    if (isDeleted) {
      restoreHandler()
    } else {
      deleteHandler()
    }
  }
  return (
    <Tooltip title={`Click to ${isDeleted ? 'restore' : 'delete'} this set`}>
      <Icon
        isDisabled={disabled}
        disabled={disabled}
        isLocked={disabled}
        onClick={handleChange}
      />
    </Tooltip>
  )
}

DeleteSwitchByValue.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  isDeleted: PropTypes.bool,
  restoreHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
}

DeleteSwitchByValue.defaultProps = {
  disabled: false,
  isDeleted: false,
}

// ! Fixes tooltip visibility if HideSwitch disabled
DeleteSwitchByValue.__ANT_BUTTON = true

export default DeleteSwitchByValue
