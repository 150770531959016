import CheckboxIcon from 'components/CheckboxIcon'
import { FIELD_NAMES } from 'const/forms'
import React from 'react'
import { Actions, StyledButton } from '../Shops.styles'
import { RULE_TYPE_FILTERS } from 'const/shops'
import { formatUrl } from '../../../helpers/formatters/fields'

export const localizedShopsColumns = ({
  filters,
  sorter,
  countriesFilters,
  handleEditLocalizedShop,
  handleCouponsEdit,
}) => [
  {
    title: 'ID',
    dataIndex: FIELD_NAMES.id,
    key: FIELD_NAMES.id,
    width: 80,
    fixed: 'left',
  },
  {
    title: 'Country',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.countryCode],
    key: FIELD_NAMES.countryCode,
    width: 80,
    filters: countriesFilters,
    filteredValue: filters?.[FIELD_NAMES.countryCode] || null,
    filterMultiple: false,
  },
  {
    title: 'Domain',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.domain],
    key: FIELD_NAMES.domain,
    width: 100,
    render: (domain, { url }) => (
      <a rel="noreferrer noopener" href={formatUrl(url)} target="_blank">
        {domain}
      </a>
    ),
  },
  {
    title: 'Rule Type',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.ruleType],
    key: FIELD_NAMES.ruleType,
    width: 90,
    filters: RULE_TYPE_FILTERS,
    filteredValue: filters?.[FIELD_NAMES.ruleType] || null,
    filterMultiple: false,
  },
  {
    title: 'Rule ID',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.ruleId],
    key: FIELD_NAMES.ruleId,
    width: 80,
  },
  {
    title: 'Default',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.isDefault],
    render: (isDefault) => <CheckboxIcon checked={isDefault} />,
    align: 'center',
    key: FIELD_NAMES.isDefault,
    width: 90,
  },
  {
    title: 'View Coupons',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.showCoupons],
    render: (viewCoupons) => <CheckboxIcon checked={viewCoupons} />,
    align: 'center',
    key: FIELD_NAMES.showCoupons,
    width: 90,
  },
  {
    title: 'Coupons Scanner',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.couponsScanner],
    render: (couponsScanner) => <CheckboxIcon checked={couponsScanner} />,
    align: 'center',
    key: FIELD_NAMES.couponsScanner,
    width: 90,
  },
  {
    title: 'Coupons Scraping',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.couponsScraping],
    render: (couponsScraping) => <CheckboxIcon checked={couponsScraping} />,
    align: 'center',
    key: FIELD_NAMES.couponsScraping,
    width: 100,
  },
  {
    title: 'Retailer ID',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.retailerId],
    key: FIELD_NAMES.retailerId,
    width: 90,
  },
  {
    title: 'Tags',
    dataIndex: [FIELD_NAMES.attributes, FIELD_NAMES.tagsCount],
    key: FIELD_NAMES.tagsCount,
    width: 90,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
    fixed: 'right',
    width: 170,
    render: (_, shop) => {
      const onEdit = () => handleEditLocalizedShop(shop[FIELD_NAMES.id])

      const onCouponsEdit = () => handleCouponsEdit(shop)
      return (
        <Actions>
          <StyledButton onClick={onEdit}>Edit</StyledButton>
          <StyledButton onClick={onCouponsEdit}>Coupons settings</StyledButton>
        </Actions>
      )
    },
  },
]
